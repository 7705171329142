import {AxiosError} from "axios"
import {Alert} from "./alert"
import {PromiseResponse} from "../interfaces/http"

async function requestData<T>(request: (...params: any) => PromiseResponse<T>, ...params: any): Promise<T | null> {
  try {
    const response = await request(...params)
    if (response.data.status || response.data.status_code === 0) {
      if (response.data.result === null) return true as unknown as T
      else return response.data.result
    } else {
      console.error(response)
      if (response.data.status_code === 1001) {
        Alert.displayErrorMessage("Произошла внутренняя ошибка на сервере!")
      } else if (response.data.status_code === 1002) {
        Alert.displayErrorMessage("Введенного кода не существует!")
      } else if (response.data.status_code === 1003) {
        Alert.displayErrorMessage("Пользователь заблокирован!")
      } else if (response.data.status_code === 1004) {
        Alert.displayErrorMessage("Неверный номер телефона!")
      } else if (response.data.status_code === 1005) {
        console.log(response.data.message)
      } else if (response.data.status_code === 1006) {
        Alert.displayErrorMessage("У пользователя не хватает прав на это действие!")
      } else if (response.data.status_code === 1007) {
        Alert.displayErrorMessage("Неизвестный сотовый оператор!")
      } else if (response.data.status_code === 1008) {
        Alert.displayErrorMessage("Одно значение должно быть пустое!")
      } else if (response.data.status_code === 1009) {
        Alert.displayErrorMessage("Количество не должно превышать реальное количество!")
      } else if (response.data.status_code === 1010) {
        Alert.displayErrorMessage("Адрес должен быть заполнен!")
      } else if (response.data.status_code === 1011) {
        Alert.displayErrorMessage("Действие СМС кода истекло!")
      } else if (response.data.status_code === 1012) {
        Alert.displayErrorMessage("Склад не определен!")
      } else if (response.data.status_code === 1013) {
        Alert.displayErrorMessage("Не удалось отправить СМС!")
      } else if (response.data.status_code === 1014) {
        Alert.displayErrorMessage("Количество некорректное!")
      } else if (response.data.status_code === 1015) {
        Alert.displayErrorMessage("Неверный ИИН!")
      } else if (response.data.status_code === 1050) {
        Alert.displayErrorMessage("Неверная почта!")
      } else if (response.data.status_code === 1076) {
        Alert.displayErrorMessage("Настройки SEO не найдены!")
      } else if (response.data.status_code === 2001) {
        let hasCustomErrorMessage = false
        params.forEach((param: any) => {
          if (
            param.customErrorMessage &&
            param.customErrorMessage?.code === 2001 &&
            param.customErrorMessage?.message
          ) {
            Alert.displayErrorMessage(param.customErrorMessage.message)
            hasCustomErrorMessage = true
          }
        })
        if (!hasCustomErrorMessage)
          Alert.displayErrorMessage(`Произошла неизвестная ошибка на сервере!\n${response.data.result}`)
      } else if (response.data.status_code === 2002) {
        Alert.displayErrorMessage("Неподдерживаемый тип тела запроса!")
      } else if (response.data.status_code === 2003) {
        Alert.displayErrorMessage("Тело запроса слишком большое!")
      } else if (response.data.status_code === 2004) {
        Alert.displayErrorMessage("Неподдерживаемый формат файла!")
      } else if (response.data.status_code === 2005) {
        Alert.displayErrorMessage("Не все изображения заполнены!")
      } else if (response.data.status_code === 2006) {
        Alert.displayErrorMessage("Не найден статус заказа!")
      } else if (response.data.status_code === 2007) {
        Alert.displayErrorMessage("Неверный статус заказа!")
      } else if (response.data.status_code === 2008) {
        Alert.displayErrorMessage("Для данного заказа должен быть указан скоринг!")
      } else if (response.data.status_code === 2009) {
        Alert.displayErrorMessage("Корзина не может быть пустой!")
      } else if (response.data.status_code === 2020) {
        Alert.displayErrorMessage("Заказ не существует!")
      } else if (response.data.status_code === 2021) {
        Alert.displayErrorMessage("Изображение не найдено!")
      } else if (response.data.status_code === 2030) {
        Alert.displayErrorMessage("Товары не найдены!")
      } else if (response.data.status_code === 2031) {
        Alert.displayErrorMessage("IMEI должен быть заполнен!")
      } else if (response.data.status_code === 2032) {
        Alert.displayErrorMessage("Страховка девайса недоступна!")
      } else if (response.data.status_code === 2033) {
        Alert.displayErrorMessage("Страхование жизни недоступна!")
      } else if (response.data.status_code === 2034) {
        Alert.displayErrorMessage("Заказ не может быть отменен!")
      } else if (response.data.status_code === 2044) {
        Alert.displayErrorMessage("Картинка услуги не может быть пустой!")
      } else if (response.data.status_code === 2045) {
        Alert.displayErrorMessage("Название услуги не может быть пустой!")
      } else if (response.data.status_code === 5004) {
        Alert.displayErrorMessage("Неизвестная ошибка базы данных!")
      } else if (response.data.status_code === 5005) {
        Alert.displayErrorMessage("Данная сущность уже существует!")
      } else if (response.data.status_code === 5006) {
        Alert.displayErrorMessage("Данной сущности не существует!")
      } else if (response.data.status_code === 5007) {
        Alert.displayErrorMessage("Сущность используется в другом месте!")
      } else if (response.data.status_code === 5050) {
        Alert.displayErrorMessage("Ошибка интеграции брокера!")
      } else if (response.data.status_code === 5055) {
        Alert.displayErrorMessage("Ошибка интеграции резервации!")
      } else if (response.data.status_code === 5060) {
        Alert.displayErrorMessage("Ошибка резервации!")
      } else if (response.data.status_code === 5065) {
        Alert.displayErrorMessage("Товаров нет в наличии!")
      } else if (response.data.status_code === 5070) {
        Alert.displayErrorMessage("Дубликат отмены!")
      } else if (response.data.status_code === 5075) {
        Alert.displayErrorMessage("Дубликат завершения!")
      } else if (response.data.status_code === 5080) {
        Alert.displayErrorMessage("Дубликат отмены!")
      } else if (response.data.status_code === 6001) {
        Alert.displayErrorMessage(
          // @ts-ignore
          `Товары в акции участвуют в другой акции: ${response.data.result[0].models.join(",")}`,
        )
        return response.data.result
      } else if (response.data.status_code === 7006) {
        Alert.displayErrorMessage("У клиента нет активных тарифов!")
      } else if (response.data.status_code === 7007) {
        Alert.displayErrorMessage("У клиента нет активного контракта Beeline!")
      } else if (response.data.status_code === 7008) {
        Alert.displayErrorMessage("Активный контракт Билайна!")
      } else if (response.data.status_code === 7009) {
        Alert.displayErrorMessage("Билайн имеет активный контракт с услугами!")
      } else if (response.data.status_code === 7010) {
        Alert.displayErrorMessage("Тариф билайн не доступен!")
      } else if (response.data.status_code === 7101) {
        Alert.displayErrorMessage("Ошибка интеграции 1С!")
      } else if (response.data.status_code === 7060) {
        Alert.displayErrorMessage("Оплата заказа уже существует!")
      } else if (response.data.status_code === 8151) {
        Alert.displayErrorMessage("Заказ уже зарезервирован!")
      } else if (response.data.status_code === 3210) {
        Alert.displayErrorMessage("Использованный промо-купон не может быть погашен!")
      } else if (response.data.status_code === 3110) {
        Alert.displayErrorMessage("Акция уже опубликована. Вы не можете изменить параметры выдачи!")
      } else if (response.data.status_code === 1077) {
        Alert.displayErrorMessage("SEO настройка с этим url уже существует!")
      } else if (response.data.status_code === 8008) {
        Alert.displayErrorMessage("Выбранный товар/услуга находится в другой бонусной группе!")
      } else if (response.data.status_code === 7700) {
        Alert.displayErrorMessage("У выбранного товара/услуги отсутствует бонусная группа!")
      } else if (response.data.status_code === 25500) {
        Alert.displayErrorMessage("Акция с таким именем уже существует. Введите другое название!")
      } else if (response.data.status_code === 25328) {
        Alert.displayErrorMessage("На одной ТТ не может быть несколько терминалов одного банка")
      } else if (response.data.status_code === 25329) {
        Alert.displayErrorMessage("Один терминал может быть включен только на одной ТТ")
      } else if (response.data.status_code === 25330) {
        Alert.displayErrorMessage("Адрес подключения должен быть уникальным для всех активных терминалов")
      } else if (response.data.status_code === 25331) {
        Alert.displayErrorMessage("ID терминала обязателен для заполнения")
      } else if (response.data.status_code === 25332) {
        Alert.displayErrorMessage("Серийный номер терминала обязателен для заполнения")
      } else if (response.data.status_code === 25333) {
        Alert.displayErrorMessage("Адрес подключения  обязателен для заполнения")
      } else if (response.data.status_code === 1088) {
        Alert.displayErrorMessage("Товар уже зарезервирован в другом предзаказе")
      } else if (response.data.status_code === 6103) {
        // @ts-ignore
        Alert.displayErrorMessage(`Услуга присутствует в пакете:  ${response.data.result.join(",")}`)
      } else if (response.data.status_code === 9001) {
        Alert.displayErrorMessage("Возврат уже одобрен/отклонен")
      } else if (response.data.status_code === 19004) {
        Alert.displayErrorMessage("Длина бейджика должна быть меньше 25 символов")
      } else if (response.data.status_code === 19005) {
        Alert.displayErrorMessage("Товар уже присутствует в другой акции либо товарах исключении")
      } else if (response.data.status_code === 7100) {
        Alert.displayErrorMessage(`${response.data.result}`)
      } else if (response.data.status_code === 1410) {
        Alert.displayErrorMessage(`${response.data.message}`)
      } else if (response.data.status_code === 6103) {
        // @ts-ignore
        Alert.displayErrorMessage(`Услуга присутствует в пакете:  ${response.data.result.join(",")}`)
      } else if (response.data.status_code === 8002) {
        Alert.displayErrorMessage("Дата начала приказа не может быть раньше чем сегодня!")
      } else if (response.data.status_code === 8019) {
        Alert.displayErrorMessage("Неверная дата начала приказа!")
      } else if (response.data.status_code === 1413) {
        Alert.displayErrorMessage("Инвентаризация должна быть завершена в 1С")
      } else if (response.data.status_code === 19006) {
        Alert.displayErrorMessage("Продукт в списке уже есть в списке исключение")
      } else if (response.data.status_code === 1460) {
        Alert.displayErrorMessage("Список продуктов Al Style пуст!")
      } else if (response.data.status_code === 1461) {
        Alert.displayErrorMessage("Недействительное значение для мин остатка!")
      } else if (response.data.status_code === 1462) {
        Alert.displayErrorMessage("Недействительное значение для срока доставки!")
      } else if (response.data.status_code === 1463) {
        Alert.displayErrorMessage("Недействительное значение для коэф наценки!")
      } else if (response.data.status_code === 10007) {
        Alert.displayErrorMessage("Все ставки в приказе должны быть заполнены!")
      } else {
        Alert.displayErrorMessage("Произошла внутренняя ошибка на сервере!")
      }
      return null
    }
  } catch (error) {
    const err = error as AxiosError
    if (err.response?.status === 404) {
      Alert.displayErrorMessage("API эндпоинт не найден либо был удален!")
    } else if (err.response?.status === 500) {
      Alert.displayErrorMessage("Критическая ошибка на бэкенде!")
    } else {
      Alert.displayErrorMessage("Произошла ошибка на сервере!")
    }
    console.error("err.res", err.response)
    return null
  }
}

export default requestData
